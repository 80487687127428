<template>
  <div class="case-content">
    <div class="case-title">区域负责人管理</div>
    <div class="btn-case">
      <el-button size="medium" class="btn-green" @click="addArea">新增区域负责人</el-button>
    </div>
    <el-table :data="principalList" style="width: 100%; flex: 1;" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '50px', color: '#444444', background: '#F5F7FA',fontSize: '16px'}"
              :cell-style="{fontSize: '14px',color: '#666666',height: '70px'}">
      <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
      <el-table-column prop="name" align="center" label="姓名">
        <template slot-scope="scope">
          <span class="text-overflow">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="area" align="center" label="负责区域"></el-table-column>
      <el-table-column prop="phone" align="center" label="电话"></el-table-column>
      <el-table-column prop="addtime" align="center" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作" width="220">
        <template slot-scope="scope">
<!--          <el-button type="primary" size="small" plain>查看</el-button>-->
          <el-button type="primary" size="small" @click="editArea(scope.row.area_manager_id)">编辑</el-button>
          <el-button type="danger" size="small" @click="deleteArea(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="pages-center"
        :current-page="principalPages.currentPageNum"
        :page-size="principalPages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="principalPages.total"
        @current-change="principalCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import {getAreaManagerList, areaManagerDel} from '@/utils/apis'
export default {
  name: "areaManagerIndex",
  data(){
    return {
      principalList:[],
      principalPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total:0
      },
    }
  },
  mounted() {
    this.getPrincipalList()
  },
  methods:{
    getPrincipalList(){
      let params = {
        type:2,
        paging:1,
        page:this.principalPages.currentPageNum,
        pageSize:this.principalPages.eachPageNum
      }
      getAreaManagerList({params}).then((res)=>{
        this.principalList = res.data.list;
        this.principalPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    addArea(){
      this.$router.push('/areaManager/add')
    },
    editArea(id){
      this.$router.push({
        path:'/areaManager/add',
        query:{
          id:id
        }
      })
    },
    deleteArea(row){
      this.$confirm(`是否确认删除【${row.name}】`, '删除区域负责人',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        let params = {
          area_manager_id:row.area_manager_id
        }
        areaManagerDel(params).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose:()=>{
              this.getPrincipalList()
            }
          })
        }).catch((err)=>{
          console.log('err',err)
        })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    principalCurrentChange(val){
      this.principalPages.currentPageNum = val;
      this.getPrincipalList()
    }
  }
}
</script>

<style scoped lang="scss">
.case-content{
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding:0 20px;
  box-sizing: border-box;
  .case-title{
    border-bottom: 1px solid #dcdcdc;
    padding: 30px 0;
    box-sizing: border-box;
    font-size: 16px;
    color: #222;
  }
  .btn-case{
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px 0;
    box-sizing: border-box;
  }
}
</style>